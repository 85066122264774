import { useMemo, useState } from 'react';
import { PiUserFocusFill } from 'react-icons/pi';

import { type GetDistributorContext } from '../../../../model/Distributor';
import { useDistributors } from '../../../../network/apis/distributors/use-distributors';
import {
  FilterableFields,
  FilterOperators,
} from '../../../../network/apis/types';
import { MultiSelectRadioDropdown } from '../components/MultiSelectRadioDropdown';
import { useFilterStore } from '../useFilterStore';

export function PrimaryAssuredFilter({
  context,
}: {
  context: (typeof GetDistributorContext)[number];
}) {
  const {
    filters,
    selectedDistributors,
    setSelectedDistributors,
    updateFilter,
    removeFilter,
  } = useFilterStore((state) => ({
    filters: state.filters.find(
      (filter) => filter.field === FilterableFields.DISTRIBUTOR_ID,
    )?.values,
    selectedDistributors: state.selectedDistributors,
    setSelectedDistributors: state.setSelectedDistributors,
    updateFilter: state.updateFilter,
    removeFilter: state.removeFilter,
  }));
  const [query, setQuery] = useState<string>('');
  const {
    data: distributors,
    isError,
    isLoading,
  } = useDistributors({
    params: {
      paginate: false,
      order: 'asc',
      query,
      include_archived: true,
      context,
    },
  });

  const distributorOptions = useMemo(
    () =>
      distributors?.map(({ legal_name, id }) => ({
        label: legal_name,
        value: String(id),
      })),
    [distributors],
  );

  const appliedOptions = useMemo(
    () =>
      distributorOptions?.filter((option) => filters?.includes(option.value)) ??
      [],
    [distributorOptions, filters],
  );

  const nonAppliedOptions = useMemo(
    () =>
      distributorOptions?.filter(
        (option) => !filters?.includes(option.value),
      ) ?? [],
    [distributorOptions, filters],
  );

  const onSubmit = () =>
    updateFilter({
      field: FilterableFields.DISTRIBUTOR_ID,
      operator: FilterOperators.EQUALS,
      values: selectedDistributors,
    });

  const onReset = () => removeFilter(FilterableFields.DISTRIBUTOR_ID);

  return (
    <MultiSelectRadioDropdown
      optionsCount={filters?.length}
      selected={selectedDistributors}
      setSelected={setSelectedDistributors}
      onSearch={setQuery}
      onReset={onReset}
      name="distributor"
      label="Primary Assured"
      dropdownIcon={<PiUserFocusFill />}
      nonAppliedOptions={nonAppliedOptions}
      appliedOptions={appliedOptions}
      onSubmit={onSubmit}
      placeholder={'Search primary assured...'}
      isError={isError}
      isLoading={isLoading}
    />
  );
}

import { CiCircleQuestion } from 'react-icons/ci';
import { FaCopy, FaPen } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import {
  Button,
  LinkButton,
  Tooltip,
  TooltipTriggerWrapper,
} from '@breezeai-frontend/cargo-ui';
import { useQuery } from '@tanstack/react-query';

import { usePlatform } from '../../../../context/PlatformContext';
import { QuoteStatusEnum } from '../../../../network/apis/quotes/types';
import { certificateQueries } from '../../network/queries';
import { type CertificatesRouteParams } from '../../types';

// TODO rename to file CertificateActions
export function CertificateActions() {
  const { certificateId, policyId } = useParams<CertificatesRouteParams>();
  const { data: certificate, isLoading } = useQuery(
    certificateQueries.details({ policyId }),
  );
  // TODO how should this interact with the hidden state of the button when determined by permissions?
  const isEditDisabled = certificate?.associated_with_open_claim || isLoading;

  const { isWtw } = usePlatform();
  const isArchived = certificate?.quote?.status === QuoteStatusEnum.ARCHIVED;
  const isAuthorizedToEditCertificate =
    certificate?.quote?.status === QuoteStatusEnum.POLICY_ISSUED
      ? Boolean(certificate?.permissions?.edit_issued)
      : Boolean(certificate?.permissions?.create);
  const isAuthorizedToDuplicateCertificate = Boolean(
    certificate?.permissions?.create,
  );

  return (
    <div className="flex flex-row sm:items-center sm:justify-between text-nowrap gap-2 w-full">
      {/* External certificate id */}

      <div className="flex sm:flex-row flex-col items-center gap-2">
        {isAuthorizedToDuplicateCertificate && (
          <LinkButton
            data-testid="duplicate-certificate-button"
            variant="secondary"
            label="Duplicate"
            leftIcon={<FaCopy />}
            width="auto"
            href={`/certificates/duplicate/${certificateId}/${policyId}`}
          />
        )}
        {isAuthorizedToEditCertificate && (
          <LinkButton
            data-testid="edit-certificate-button"
            variant="secondary"
            label="Edit"
            leftIcon={<FaPen />}
            width="auto"
            isDisabled={isArchived || isEditDisabled}
            href={`/certificates/update/${certificateId}/${policyId}`}
          />
        )}

        {isWtw && isArchived && (
          <TooltipTriggerWrapper delay={0}>
            <Button variant="icon">
              <CiCircleQuestion size={24} />
            </Button>
            <Tooltip placement="top">Primary assured is archived.</Tooltip>
          </TooltipTriggerWrapper>
        )}
      </div>
    </div>
  );
}

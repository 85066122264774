import { type PropsWithChildren, useContext, useEffect } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { AuthContext } from '../context/auth/AuthContext';
import { usePlatform } from '../context/PlatformContext';
import { useShouldBlockUser } from './blockedGCIDs.temp';

export function BreezeProtectedRoute({ children }: PropsWithChildren) {
  const { authenticated } = useContext(AuthContext);

  return authenticated ? <>{children}</> : <Navigate to="/login" replace />;
}

export function TradeFlowProtectedRoute({ children }: PropsWithChildren) {
  // TODO: remove once the distributors setup is complete.
  // https://breeze.fibery.io/Breeze/Task/Remove-hardcoded-list-of-GCIDs-3183
  const shouldBlockUser = useShouldBlockUser();

  const [searchParams] = useSearchParams();
  const gcId = searchParams.get('gcid');

  const { isWtw } = usePlatform();

  const { authenticated, user } = useContext(AuthContext);

  const redirectPath = isWtw && gcId ? `/login?gcid=${gcId}` : '/login';

  if (!authenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  if (!user?.has_access_to_platform || shouldBlockUser) {
    // TODO this should throw an error that is handled by the error boundary
    return <Navigate to="/unassigned" replace />;
  }

  return <>{children}</>;
}

export const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();

  const { isWtw } = usePlatform();

  const { setRedirectToOnLogin } = useContext(AuthContext);

  // Set the redirect path if the user is not logged in - to be used after login
  useEffect(() => {
    if (pathname !== '/login' && pathname !== '/' && setRedirectToOnLogin)
      setRedirectToOnLogin(pathname);
  }, [pathname, setRedirectToOnLogin]);

  return isWtw ? (
    <TradeFlowProtectedRoute>{children}</TradeFlowProtectedRoute>
  ) : (
    <BreezeProtectedRoute>{children}</BreezeProtectedRoute>
  );
};

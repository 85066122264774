import { Table, Typography } from '@breeze-ai/ui-library';
import { LinkButton } from '@breezeai-frontend/cargo-ui';

import { type Claim } from '../../../model/Claim';
import { useAppNavigation } from '../../../router/use-app-navigation';
import { useClaimsTableColumns } from '../../claims/ClaimsTable/use-claims-table-columns';
import labels from '../../labels';
import { PageSection } from '../PageSection/PageSection';
import styles from './PolicyClaims.module.scss';

export const DetailsPageClaimsSection = ({
  certificateId,
  claims,
  isAuthorizedToCreateClaims,
}: {
  certificateId: string;
  claims: Claim[];
  isAuthorizedToCreateClaims: boolean;
}) => {
  const { navigateToClaim } = useAppNavigation();
  const columns = useClaimsTableColumns();

  const claimsCount = claims.length;

  return (
    <PageSection data-testid="policy-claims">
      <div className={styles.header}>
        <Typography level="h4">
          Claims {claimsCount ? `(${claimsCount})` : ''}
        </Typography>
        {isAuthorizedToCreateClaims && (
          <LinkButton
            data-testid="file-a-claim-button"
            width="fixed"
            variant="secondary"
            href={`/claims/start?certificate_id=${certificateId}`}
            label="File a Claim"
          />
        )}
      </div>
      {claimsCount ? (
        <div className={styles.claimsTableWrapper}>
          <Table<Claim>
            rows={claims ?? []}
            columns={columns}
            disableSelectionOnClick
            density="comfortable"
            getRowId={(row) => row.id}
            hideFooter={true}
            showToolbar={false}
            disableVirtualization={true}
            onRowClick={({ id }) => navigateToClaim(id)}
          />
        </div>
      ) : (
        <Typography level="base" variant="input">
          No claims were submitted against this {labels.terms.policy.singular}.
        </Typography>
      )}
    </PageSection>
  );
};

import { type Dispatch, type SetStateAction, useMemo, useState } from 'react';
import { PiUserFocusFill } from 'react-icons/pi';

import { type GetDistributorContext } from '../../../../model/Distributor';
import { useDistributors } from '../../../../network/apis/distributors/use-distributors';
import { MultiSelectRadioDropdown } from '../components/MultiSelectRadioDropdown';
import { useTableFilters } from '../hooks/useTableFilters.ts';

// TODO rebuild move to cargo ui
export function PrimaryAssuredFilterV2({
  filterName,
  setSearchParams,
  searchParams,
  context,
}: {
  filterName: string;
  // TODO link to useTableFilters props
  setSearchParams: Dispatch<SetStateAction<URLSearchParams>>;
  searchParams: URLSearchParams;
  context: (typeof GetDistributorContext)[number];
}) {
  const [query, setQuery] = useState<string>('');

  const { upsertFilter, removeFilter, getAppliedAndNonAppliedOptions } =
    useTableFilters({
      setSearchParams,
    });
  const filterValues = searchParams.get(filterName)?.split('_');

  const {
    data: distributors,
    isError,
    isLoading,
  } = useDistributors({
    params: {
      paginate: false,
      order: 'asc',
      query,
      include_archived: true,
      context,
    },
  });

  const distributorOptions = useMemo(
    () =>
      distributors?.map(({ legal_name, id }) => ({
        label: legal_name,
        value: String(id),
      })),
    [distributors],
  );

  const [selectedDistributors, setSelectedDistributors] = useState<string[]>(
    filterValues ?? [],
  );
  const { appliedOptions, nonAppliedOptions } = useMemo(
    () => getAppliedAndNonAppliedOptions(filterValues, distributorOptions),
    [filterValues, distributorOptions, getAppliedAndNonAppliedOptions],
  );

  return (
    <MultiSelectRadioDropdown
      optionsCount={appliedOptions?.length}
      selected={selectedDistributors}
      setSelected={setSelectedDistributors}
      onSearch={setQuery}
      onReset={() => removeFilter(filterName)}
      name="distributor"
      label="Primary Assured"
      dropdownIcon={<PiUserFocusFill />}
      nonAppliedOptions={nonAppliedOptions}
      appliedOptions={appliedOptions}
      onSubmit={() =>
        upsertFilter({
          filterName,
          values: selectedDistributors,
        })
      }
      placeholder={'Search primary assured...'}
      isError={isError}
      isLoading={isLoading}
    />
  );
}

import { useCallback, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSearchParams } from 'react-router-dom';
import {
  type BaseQueryParams,
  type PageState,
  ServerSideTableV2,
} from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';
import { type GridRowParams } from '@mui/x-data-grid-pro';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { type Claim } from '../../../model/Claim';
import { getClaims } from '../../../network/apis/claims/claims';
import { type ClaimsSortableFields } from '../../../network/apis/claims/types';
import {
  FilterableFields,
  type FilterBaseParam,
} from '../../../network/apis/types';
import { useAppNavigation } from '../../../router/use-app-navigation';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { useSectionError } from '../../components/Section/SectionContext';
import { useTableFilters } from '../../components/TableFilters/hooks/useTableFilters';
import { useTableQueryParamsV2 } from '../../components/TableFilters/hooks/useTableQueryParamsV2';
import { getFilterPayload } from '../../components/TableFilters/utils';
import { CargoOwnerFilterV2 } from '../../components/TableFilters/V2/CargoOwnerFilterV2';
import { CreatedByFilterV2 } from '../../components/TableFilters/V2/CreatedByFilterV2';
import { CreatedOnFilterV2 } from '../../components/TableFilters/V2/CreatedOnFilterV2';
import { PrimaryAssuredFilterV2 } from '../../components/TableFilters/V2/PrimaryAssuredFilterV2';
import styles from './ClaimsTable.module.scss';
import { useClaimsTableColumns } from './use-claims-table-columns';

const filters = [
  FilterableFields.CREATED_AT,
  FilterableFields.CREATED_BY_USER_ID,
  FilterableFields.CUSTOMER_ID,
  FilterableFields.DISTRIBUTOR_ID,
];

export const ClaimsTable = () => {
  const { isWtw } = usePlatform();
  const [filtersComponentKey, setFiltersComponentKey] = useState<number>(0);
  const refreshFilterState = () => {
    clearAllFilters(filters);
    setFiltersComponentKey((prev) => prev + 1);
  };

  const enableFilters = useFeatureToggle('enable_column_filters');
  const [searchParams, setSearchParams] = useSearchParams();
  const { clearAllFilters } = useTableFilters({
    setSearchParams,
  });

  // TODO remove coercion
  const filterPayload = filters
    .map((filter) => getFilterPayload(filter, searchParams))
    .filter((item) => !!item) as FilterBaseParam<FilterableFields>[];

  const {
    getQuerySearchURLParam,
    getSortOrderFromURLParam,
    getSortByFromURLParam,
    upsertTableQuery,
  } = useTableQueryParamsV2(setSearchParams);

  const queryParams = {
    limit: 10,
    query: getQuerySearchURLParam(searchParams),
    sortBy: getSortByFromURLParam(searchParams),
    order: getSortOrderFromURLParam(searchParams),
  };

  const { navigateToClaim } = useAppNavigation();
  const columns = useClaimsTableColumns();
  const { setError } = useSectionError();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);

  const fetchClaimsPage = useCallback(
    async (params: BaseQueryParams): Promise<PageState<Claim>> => {
      return getClaims({
        limit: params?.limit,
        query: params?.query,
        order: params?.order,
        sort_by: params?.sortBy as ClaimsSortableFields,
        page_pointer: params?.pagePointer,
        filters: filterPayload,
        paginate: true,
      })
        .then((page) => {
          return {
            records: page.claims,
            nextPagePointer: page.next_page_pointer,
            prevPagePointer: page.prev_page_pointer,
            totalRows: page.total_num_records,
          };
        })
        .catch((e) => {
          reportException(e);
          setError(e);
          return { records: [] };
        });
    },
    [setError, filterPayload],
  );

  return (
    <div className="h-full w-full">
      <ServerSideTableV2<Claim>
        testId="claims-table"
        fetchPage={fetchClaimsPage}
        columns={columns}
        getRowId={(row) => row.id}
        getRowClassName={() => styles.quoteRow}
        onRowClick={({ row }: GridRowParams<Claim>) =>
          navigateToClaim(`${row.id}`)
        }
        actions={{ search: true, pageSize: true, filters: true }}
        searchProps={{
          placeholder: 'Search Claim Number',
          defaultValue: getQuerySearchURLParam(searchParams),
        }}
        setCurrentPageNumber={setCurrentPageNumber}
        currentPageNumber={currentPageNumber}
        queryParams={queryParams}
        setQueryParams={upsertTableQuery}
        sortModel={[
          {
            field: queryParams.sortBy ?? 'reported_time',
            sort: queryParams.order,
          },
        ]}
        autoHeight={false}
        filters={
          enableFilters ? (
            <ErrorBoundary
              fallbackRender={() => <></>}
              key={filtersComponentKey}
            >
              <div className={styles.filters}>
                <ErrorBoundary fallbackRender={() => <></>}>
                  <CreatedOnFilterV2
                    filterName={FilterableFields.CREATED_AT}
                    setSearchParams={setSearchParams}
                    searchParams={searchParams}
                  />
                </ErrorBoundary>

                <ErrorBoundary fallbackRender={() => <></>}>
                  <CreatedByFilterV2
                    filterName={FilterableFields.CREATED_BY_USER_ID}
                    setSearchParams={setSearchParams}
                    searchParams={searchParams}
                  />
                </ErrorBoundary>
                {isWtw && (
                  <ErrorBoundary fallbackRender={() => <></>}>
                    <PrimaryAssuredFilterV2
                      filterName={FilterableFields.DISTRIBUTOR_ID}
                      setSearchParams={setSearchParams}
                      searchParams={searchParams}
                      context="claims_filter"
                    />
                  </ErrorBoundary>
                )}
                <ErrorBoundary fallbackRender={() => <></>}>
                  <CargoOwnerFilterV2
                    filterLabel={isWtw ? 'Named Assured' : 'Cargo owner'}
                    filterName={FilterableFields.CUSTOMER_ID}
                    setSearchParams={setSearchParams}
                    searchParams={searchParams}
                  />
                </ErrorBoundary>
                <Button
                  onPress={refreshFilterState}
                  variant="ghost"
                  size="small"
                  isDisabled={!filterPayload.length}
                  width="fixed"
                  label="Clear filters"
                />
              </div>
            </ErrorBoundary>
          ) : undefined
        }
      />
    </div>
  );
};

import { formatPrice } from '@breezeai-frontend/cargo-ui';

import { useFeatureToggle } from '../../context/auth/auth-hooks';
import { usePlatform } from '../../context/PlatformContext';
import { type Quote } from '../../model/Quote';
import { getAppLocale } from '../../utils/locale';

export const useNetCostDisplayValue = (
  netCost: Quote['distributor_net_cost_value'],
  netCostCurrency: Quote['distributor_net_cost_currency'],
) => {
  const enableNetCost = useFeatureToggle('show_distributor_net_cost');

  const locale = getAppLocale();
  const { isBreeze } = usePlatform();

  if (!netCost || !netCostCurrency || !enableNetCost || !isBreeze) {
    return undefined;
  }

  const netCostPrice = formatPrice({
    currency: netCostCurrency.code,
    value: netCost,
    locale,
  });

  return netCostPrice;
};

import { useState } from 'react';
import { Typography } from '@breeze-ai/ui-library';
import { useSuspenseQuery } from '@tanstack/react-query';

import { PDFStatus } from '../../../../network/apis/policies/types';
import {
  type Asset,
  AssetsList,
} from '../../../components/FileList/AssetsList/AssetsList';
import { PageSection } from '../../../components/PageSection/PageSection';
import { certificateQueries } from '../../network/queries';

const MAX_WAIT_TIME = 20000;

export function PollingFileList({ policyId }: { policyId: string }) {
  const [isTimeoutError, setIsTimeoutError] = useState(false);

  const {
    data: certificate,
    refetch,
    isFetching,
  } = useSuspenseQuery({
    ...certificateQueries.details({ policyId }),

    refetchInterval({ state }) {
      setTimeout(() => {
        setIsTimeoutError(true);
      }, MAX_WAIT_TIME);
      const docsInProgress =
        state.data?.proof_of_cover_status !== PDFStatus.InProgress &&
        state.data?.certificate_status !== PDFStatus.InProgress;

      if (isTimeoutError || docsInProgress) {
        return 0;
      }

      return 4000;
    },
  });

  const {
    open_cover,
    certificate_url,
    certificate_status,
    certificate_created_time,
    permissions,
  } = certificate ?? {};

  const isCertificateLoading =
    (certificate_status === PDFStatus.InProgress && !isTimeoutError) ||
    isFetching;
  const isCertificateTimeOutError =
    certificate_status === PDFStatus.InProgress &&
    isTimeoutError &&
    !isCertificateLoading;
  const isAuthorizedToViewCertificate = Boolean(permissions?.show_cert_pdf);
  const policyFiles: Asset[] = [
    {
      path: certificate_url,
      name: 'Certificate',
      creationDate: certificate_created_time,
      testId: 'policy-certificate',
      isLoading: isCertificateLoading,
      isError: isCertificateTimeOutError,
      isHidden: !certificate_status || !isAuthorizedToViewCertificate,
      onTryAgain: refetch,
    },
    {
      path: open_cover?.terms_and_conditions_url,
      name: 'Policy Full Terms and Conditions',
      testId: 'policy-terms-and-conditions',
      isLoading: false,
      isError: !open_cover?.terms_and_conditions_url,
      isHidden: !open_cover?.terms_and_conditions_url,
      onTryAgain: refetch,
    },
  ];

  return certificate_status ? (
    <PageSection data-testid="documents">
      <AssetsList
        assets={policyFiles}
        title={<Typography level="h4">Documents</Typography>}
      />
    </PageSection>
  ) : null;
}

import { FaInfoCircle } from 'react-icons/fa';
import { FaMoneyBills, FaShirt, FaUsers } from 'react-icons/fa6';
import { PiClockCountdownLight, PiUserFocusFill } from 'react-icons/pi';
import { Currency, Tooltip } from '@breeze-ai/ui-library';
import { Badge, toPercentage, Typography } from '@breezeai-frontend/cargo-ui';
import invariant from 'tiny-invariant';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { type CoveragePackageType, type Quote } from '../../../model/Quote';
import {
  formatCurrencyByValueAndCode,
  formatCurrencyValue,
} from '../../../utils/Internationalization';
import { getAppLocale } from '../../../utils/locale';
import { COVERAGE_PACKAGES } from '../../constants';
import { QuoteAdditionalClause } from '../../quotes/components/QuoteAdditionalClause';
import { QuotePricingDetails } from '../../quotes/components/QuotePricingDetails/QuotePricingDetails';
import {
  type JourneyStop,
  JourneySummary,
} from '../JourneySummary/JourneySummary';
import styles from './QuoteSummaryDetails.module.scss';

type QuoteDetailsProps = {
  quote: Quote;
  coverageOptions: CoveragePackageType[];
};

const getOrdinalIndicator = (day: number) => {
  const lastDigit = day % 10,
    lastTwoDigits = day % 100;
  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return day + 'st';
  }
  if (lastDigit === 2 && lastTwoDigits !== 12) {
    return day + 'nd';
  }
  if (lastDigit === 3 && lastTwoDigits !== 13) {
    return day + 'rd';
  }
  return day + 'th';
};

const formatExpiringOn = (expiring_on: string) => {
  if (!expiring_on) {
    return null;
  }

  const date = new Date(expiring_on);
  const day = date.getUTCDate(); // Use UTC methods
  const month = date.toLocaleString('default', {
    month: 'long',
    timeZone: 'UTC',
  }); // Specify UTC time zone
  const year = date.getUTCFullYear(); // Use UTC methods
  const ordinalIndicator = getOrdinalIndicator(day);
  const locale = getAppLocale();
  if (locale === 'en-US') return `${month} ${ordinalIndicator}, ${year}`;
  if (locale === 'en-GB') return `${ordinalIndicator} of ${month} ${year}`;
  return null; // Handle unsupported locales
};

export const QuoteSummaryDetails = ({
  quote,
  quote: {
    origin_place,
    origin_port,
    destination_place,
    destination_port,
    primary_transport_mode_code,
    loading_place,
    loading_transport_mode_code,
    delivery_place,
    delivery_transport_mode_code,
    customer,
    commodity_category,
    premium_value,
    premium_currency,
    insured_value = 0,
    insured_value_currency,
    deductible_value = 0,
    deductible_currency,
    freight_cost = 0,
    freight_cost_currency,
    duty_cost = 0,
    duty_cost_currency,
    coverage_package,
    expiring_on,
    quote_additional_clauses,
    premium_rate,
    uplift_percentage,
  },
  coverageOptions,
}: QuoteDetailsProps) => {
  invariant(
    primary_transport_mode_code,
    'primary_transport_mode_code is required',
  );
  const { isWtw } = usePlatform();
  const showDutyCost = useFeatureToggle('enable_shipments_duty_cost');

  const origin = origin_place ?? origin_port;
  const destination = destination_place ?? destination_port;

  const insuredValueAdditionalCosts = [
    {
      label: 'Freight Cost',
      value: freight_cost,
      currency: freight_cost_currency,
    },
    {
      label: 'Duty Cost',
      value: duty_cost,
      currency: duty_cost_currency,
      hide: !showDutyCost,
    },
  ].filter(({ value, hide }) => value && !hide);
  formatCurrencyValue({
    value: insured_value,
    currency_code: insured_value_currency,
  });

  formatCurrencyByValueAndCode(freight_cost, freight_cost_currency);

  if (!origin || !destination) {
    return null;
  }

  const stops: JourneyStop[] = [
    {
      label: 'Origin',
      location: origin,
      transportMode: primary_transport_mode_code,
      isPrimaryTransport: true,
    },
    {
      label: 'Destination',
      location: destination,
      transportMode:
        delivery_place && delivery_transport_mode_code
          ? delivery_transport_mode_code
          : null,
    },
  ];

  if (loading_place && loading_transport_mode_code) {
    stops.unshift({
      label: 'Place of loading',
      location: loading_place,
      transportMode: loading_transport_mode_code,
    });
  }

  if (delivery_place && delivery_transport_mode_code) {
    stops.push({
      label: 'Place of Delivery',
      location: delivery_place,
      transportMode: null,
    });
  }

  // There might be multiple additional clauses in the future, but
  // we are only interested in the first result for now.
  const additionalClause = quote_additional_clauses?.[0];

  return (
    <div className={styles.quoteDetailsWrapper}>
      <Typography level="h1">Here's your insurance offer</Typography>
      {isWtw && (
        <div className="flex gap-1 items-center">
          <PiUserFocusFill />
          <Typography level="base" color="secondary">
            Primary assured
          </Typography>
          <Typography level="h5">{quote.distributor?.legal_name}</Typography>
        </div>
      )}
      <div className={styles.shipmentSummary}>
        {coverageOptions.length > 1 && coverage_package && (
          <Badge outlined className="my-5" data-testid="coverage-package">
            {COVERAGE_PACKAGES[coverage_package].badgeLabel}
          </Badge>
        )}
        <div
          className="flex items-center gap-2 flex-wrap justify-center mt-7"
          data-testid="submission-summary"
        >
          <div className="flex items-center gap-2 border-r border-r-system-grey-100 pr-2">
            <FaShirt className="text-system-grey-750" />
            <Typography level="base" color="secondary">
              Commodity Category
            </Typography>
            <Typography level="base" color="primary" text="bold">
              {commodity_category}
            </Typography>
          </div>
          <div className="flex items-center gap-2 border-r border-r-system-grey-100 pr-2">
            <FaMoneyBills className="text-system-grey-750" />
            <Typography level="base" color="secondary">
              Insured Value
            </Typography>
            <Typography level="base" color="primary" text="bold">
              {insured_value_currency && (
                <span className="flex gap-1 items-center pl-1">
                  <Currency
                    value={insured_value}
                    currency={insured_value_currency}
                  />
                  <Tooltip
                    content={`Insured Value is Commercial Invoice Value + ${toPercentage(
                      uplift_percentage,
                    )}%`}
                  >
                    <FaInfoCircle data-testid="info-icon" />
                  </Tooltip>
                </span>
              )}
            </Typography>
          </div>
          <div className="flex items-center gap-2">
            <FaUsers className="text-system-grey-750" />
            <Typography level="base" color="secondary">
              Cargo Owner
            </Typography>
            <Typography level="base" color="primary" text="bold">
              {customer?.company_name}
            </Typography>
          </div>
        </div>
        <div className={styles.additionalCosts}>
          {insuredValueAdditionalCosts.map(({ label, value, currency }, i) => (
            <Typography key={i}>
              {label}{' '}
              {currency && <Currency value={value} currency={currency} />}
              {i < insuredValueAdditionalCosts.length - 1 && ` ,`}
            </Typography>
          ))}
        </div>
      </div>
      <JourneySummary stops={stops} />
      <div className="w-full max-w-[848px] flex flex-col gap-10 items-center">
        {additionalClause && (
          <QuoteAdditionalClause clause={additionalClause} />
        )}
        {premium_value && premium_currency && (
          <QuotePricingDetails
            premium_value={premium_value}
            premium_currency={premium_currency}
            premium_rate={premium_rate}
            premium_war_rate={quote.premium_war_rate}
            exchange_rate={quote.exchange_rate}
            tax={quote.tax}
            converted_customer_premium_value={
              quote.converted_customer_premium_value
            }
            converted_tax_amount={quote.converted_tax_amount}
            distributor_net_cost_value={quote.distributor_net_cost_value}
            distributor_net_cost_currency={quote.distributor_net_cost_currency}
            hide_tax_info={quote.distributor?.hide_tax_info}
          />
        )}
      </div>
      <div className={styles.additionalFees}>
        {deductible_value > 0 && deductible_currency && (
          <Typography level="subtext">
            Deductible{' '}
            <Currency
              typographyProps={{ level: 'subtext' }}
              value={deductible_value}
              currency={deductible_currency}
            />
          </Typography>
        )}
      </div>
      {expiring_on ? (
        <div className="flex items-center gap-1">
          <PiClockCountdownLight />
          <Typography level="subtext" color="secondary">
            This quote is valid until
          </Typography>
          <Typography level="subtext" color="primary" text="bold">
            {formatExpiringOn(expiring_on)}
          </Typography>
        </div>
      ) : null}
    </div>
  );
};

import { useMemo } from 'react';
import { toPercentage, TotalPriceBanner } from '@breezeai-frontend/cargo-ui';

import { formatPrice } from '../../../../../../../packages/cargo-ui/src/utils/tools';
import { usePlatform } from '../../../../context/PlatformContext';
import { type SupportedCurrencies } from '../../../../model/CurrencyValue';
import { getAppLocale } from '../../../../utils/locale';
import { QuotePriceBreakdownTooltip } from '../../../components/QuotePriceBreakdownTooltip/QuotePriceBreakdownTooltip';
import { useNetCostDisplayValue } from '../../../hooks/useNetCostDisplayValue';
import { type QuotePriceProperties } from '../../../types/quote';

interface QuotePricingDetailsProps extends QuotePriceProperties {
  premium_value: number;
  premium_currency: SupportedCurrencies;
}

export function QuotePricingDetails(props: QuotePricingDetailsProps) {
  const locale = getAppLocale();
  const { isWtw } = usePlatform();

  const {
    premium_value,
    premium_currency,
    premium_rate,
    premium_war_rate,
    tax,
    exchange_rate,
    distributor_net_cost_value,
    distributor_net_cost_currency,
  } = props;

  const priceBreakdownTooltip = useMemo(() => {
    if ((!tax || !tax.value) && !exchange_rate) {
      return undefined;
    }
    return <QuotePriceBreakdownTooltip {...props} />;
  }, [exchange_rate, tax, props]);

  const totalValue = (tax?.value ?? 0) + premium_value;
  const totalPrice = formatPrice({
    currency: premium_currency,
    value: totalValue,
    locale,
  });

  const premiumRate =
    isWtw && premium_rate ? toPercentage(premium_rate, 5) : undefined;

  const premiumWarRate = premium_war_rate
    ? toPercentage(premium_war_rate, 6)
    : undefined;

  const netCostPrice = useNetCostDisplayValue(
    distributor_net_cost_value,
    distributor_net_cost_currency,
  );

  return (
    <TotalPriceBanner
      data-testid="premium-rate"
      price={totalPrice}
      priceBreakdownTooltip={priceBreakdownTooltip}
      premiumRate={premiumRate}
      netCost={netCostPrice}
      premiumWarRate={premiumWarRate}
    />
  );
}

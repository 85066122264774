import { Currency, Typography } from '@breeze-ai/ui-library';

import { useFeatureToggle } from '../../../../context/auth/auth-hooks';
import { useNetCostDisplayValue } from '../../../hooks/useNetCostDisplayValue';
import { type QuotePriceProperties } from '../../../types/quote';
import { CurrencyExchangeRate } from '../../CurrencyExchangeRate/CurrencyExchangeRate';
import { TaxInfoTooltip } from '../../TaxInfoTooltip/TaxInfoTooltip';
import { type PriceComponentTypographyProps } from '../PremiumBreakdown';
import { PriceComponent } from '../PriceComponent/PriceComponent';

const defaults: PriceComponentTypographyProps = {
  title: { bold: true },
  currency: { bold: true },
};

interface PremiumTotalProps extends QuotePriceProperties {
  typographyProps?: PriceComponentTypographyProps;
}

export const PremiumTotal = ({
  premium_currency,
  premium_value,
  tax,
  exchange_rate,
  hide_tax_info,
  converted_customer_premium_value = 0,
  converted_tax_amount = 0,
  distributor_net_cost_value,
  distributor_net_cost_currency,
  typographyProps,
}: PremiumTotalProps) => {
  const showExchangeRate = useFeatureToggle('show_currencies_exchange_rates');
  const netCost = useNetCostDisplayValue(
    distributor_net_cost_value,
    distributor_net_cost_currency,
  );

  const titleProps = { ...defaults.title, ...typographyProps?.title };
  const currencyProps = { ...defaults.currency, ...typographyProps?.currency };

  if (!premium_value || !premium_currency) {
    return null;
  }

  const total = premium_value + (tax?.value ?? 0);
  const convertedTotal =
    converted_customer_premium_value + converted_tax_amount;

  return (
    <div className="flex flex-col gap-2">
      <div className="w-full flex justify-between items-center py-2 px-3 text-primary-text bg-system-grey-70 rounded-lg">
        <div className="flex gap-1 items-center">
          <Typography {...titleProps}>Total</Typography>
          {hide_tax_info && !!tax?.value && <TaxInfoTooltip />}
        </div>
        <Currency
          value={total}
          currency={premium_currency}
          typographyProps={{ ...currencyProps, role: 'total' }}
        />
      </div>
      <div className="flex flex-col gap-2">
        {showExchangeRate && exchange_rate && (
          <div className="flex flex-col gap-1">
            <PriceComponent
              title={`Total in ${exchange_rate.to_currency.code}`}
              name="converted-total"
              value={convertedTotal}
              currency={exchange_rate.to_currency.code}
              typographyProps={{ title: { className: 'text-primary-text' } }}
            />
            <CurrencyExchangeRate
              {...exchange_rate}
              typographyProps={{
                level: 'subtext',
                variant: 'input',
              }}
            />
          </div>
        )}
        {/* TODO: Deprecate PriceComponent in favour of formatPrice from cargo-ui */}
        {netCost && (
          <PriceComponent
            title={'Net Cost'}
            name="net-cost"
            value={distributor_net_cost_value}
            currency={distributor_net_cost_currency?.code}
            typographyProps={{ title: { className: 'text-primary-text' } }}
          />
        )}
      </div>
    </div>
  );
};

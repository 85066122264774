import { type FormApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';

import { type Quote } from '../../../../model/Quote';
import { type ConveyanceType, type Shipment } from '../../../../model/Shipment';
import { type TitledOption } from '../../../../model/TitledOption';
import { type User } from '../../../../model/User';
import { type QuoteFormData } from './QuoteForm';

export const getDefaultDistributorId = (user: User, quote: Quote | null) => {
  if (quote?.distributor_id) {
    return quote.distributor_id;
  }

  if (user.distributor.can_issue_policy) {
    return user.distributor.id;
  }

  return undefined;
};

export const getDefaultContainerModeId = (
  containerModes: Record<ConveyanceType, TitledOption[]>,
  preferredModeOfTransportCode: ConveyanceType,
  existingData: Quote | Shipment | null,
) => {
  // If there is no container mode or primary transport mode, return the first container mode for the primary MOT
  if (!existingData || !existingData.primary_transport_mode_code)
    return containerModes[preferredModeOfTransportCode][0].id;

  const { container_mode, primary_transport_mode_code } = existingData;
  return containerModes[primary_transport_mode_code].find(
    ({ title }) => title === container_mode,
  )?.id;
};

/**
 * Takes a form and a list of fields (or a single field) and
 * checks if any of the specified fields are dirty.
 * @param form
 * @param fields single field or list of fields
 * @returns true if any of the fields is dirty, false otherwise
 */
export const isAnyFieldDirty = (
  form: FormApi<QuoteFormData, typeof zodValidator>,
  fields: keyof QuoteFormData | (keyof QuoteFormData)[],
) => {
  if (!Array.isArray(fields)) return form.state.fieldMeta[fields].isDirty;

  return fields.some((field) => form.state.fieldMeta[field].isDirty);
};

/* tslint:disable */
/* eslint-disable */
/**
 * Service API Document
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const BffFrontendQuoteModelDivisionEnum = {
    Apac: 'apac',
    Europe: 'europe',
    Meta: 'meta',
    NorthAmerica: 'north_america',
    Latam: 'latam'
} as const;

export type BffFrontendQuoteModelDivisionEnum = typeof BffFrontendQuoteModelDivisionEnum[keyof typeof BffFrontendQuoteModelDivisionEnum];



